const getOperatingSystem = userAgent => {
  const osPatterns = {
    ios: /(iPhone|iPad|iPod)/,
    android: /Android \d+(\.\d+)?/,
  };

  for (const os in osPatterns) {
    if (
      osPatterns.hasOwnProperty(os) &&
      userAgent.match(osPatterns[os])
    ) {
      return os;
    }
  }
  return "Unknown";
};

const handlePDFClick = event => {
  const userAgentString = navigator.userAgent;
  const OS = getOperatingSystem(userAgentString);
  const element = event.currentTarget;
  const link = element.href;
  const PLATFORM = OS.toUpperCase();
  switch (PLATFORM) {
    case "ANDROID":
      try {
        const payloadString = JSON.stringify({
          url: link,
          isExternal: true,
        });
        JsHandler.openURL(window.btoa(payloadString));
      } catch (e) {
        window.open(link);
      }
      break;
    case "IOS":
      window.location.replace(link);
      break;
    default:
      window.open(link);
      break;
  }
  event.preventDefault();
};

export const handlePdfs = () => {
  const elementsWithDataAttribute = document.querySelectorAll(
    "a[href$='.pdf']"
  );
  elementsWithDataAttribute.forEach(element => {
    element.addEventListener("click", handlePDFClick);
  });
};
