import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import parse from "html-react-parser";
import "./index.scss";
import parserOptions from "../../../utils/tncTemplate";
import { handlePdfs } from "../../utils/webview";
import { Link } from "gatsby";
import { getGeneralTermsPagePath } from "../../utils/helpers";

const GeneralRevisionTermsPage = ({ pageContext }) => {
  const {
    title,
    content,
    pageSlug,
    lang,
    publishedDate,
  } = pageContext?.pageData;

  const publishedDateString = new Date(
    publishedDate + "+00:00"
  );

  useEffect(() => {
    handlePdfs();
  }, []);

  return (
    <Layout>
      <SEO title={title} />
      <div className={`general-terms-container`}>
        {title && <h1>{parse(title)}</h1>}
        {parse(content || "", parserOptions)}

        <p style={{ textAlign: "right" }}>
          {`Published at: ${publishedDateString.toLocaleString()} (IST)`}
          <br />
          <Link
            to={getGeneralTermsPagePath(
              "REVISIONS_LIST_PAGE",
              pageSlug,
              lang
            )}
          >
            {"All Versions"}
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default GeneralRevisionTermsPage;
